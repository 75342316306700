import { HomeworkGnbMobile } from "./Mobile/HomeworkGnbMobile";
import { HomeworkGnbDesktop } from "./Desktop/HomeworkGnbDesktop";
import {
  useHomeworkData,
  useInitializedFileInfo,
} from "../../../../hooks/homeworkV3/useHomeworkData";
import {
  useNavigateToNextContent,
  useSyllabusUnit,
} from "../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { useLogHomeworkCompleted } from "../../../../hooks/useLoadLogData";
import { enrolledHomeworkDetailsAtom } from "../../../../stores/enrolledHomeworkDetails";
import { useFileUpload } from "../../../../hooks/homeworkV3/useFileUpload";
import { usePostMyHomeworks } from "../../../../businesslogics/OrderedEnrollmentContents/repository/usePostMyHomeworks";
import { useInitializeHomeworkAll } from "../../../../businesslogics/OrderedEnrollmentContents/usecase/Initializer";
import {
  canGoNextLectureStatusValues,
  NEED_SCREENING_ALERT,
  StatusEnum,
  totalQnALink,
  UPDATE_SUCCESS_ALERT,
} from "../../../../_state/constants";
import useThrottle from "../../../../hooks/useThrottle";
import { useGetMaterialByEnrolled } from "../../../../queries/homeworkV3";

export const HomeworkGnb = ({ isOpenList, enrolledData }) => {
  const isInit = useInitializeHomeworkAll();
  const isMobile = window.innerWidth <= 819;
  const [fileInfo] = useInitializedFileInfo();
  const { enrolled_homework_id } = useParams();
  const {
    status,
    week,
    enrolledId,
    homeworkId,
    isFeedbackRequired,
    type,
    enrolledsHomeworkDetails, // db데이터
  } = useHomeworkData(enrolled_homework_id);
  const { data: materialData } = useGetMaterialByEnrolled(enrolledId, week);
  const courseId = enrolledData?.context?.course_id;
  const courses = enrolledData?.context?.courses;
  const qnaLink = materialData?.context?.qna_url ?? totalQnALink;
  const courseTitle = courses?.title;
  const syllabusUnit = useSyllabusUnit(courseId);
  const navigateToNext = useNavigateToNextContent();
  const logHomeworkCompleted = useLogHomeworkCompleted();
  const [enrolledHomeworkDetails] = useAtom(enrolledHomeworkDetailsAtom);
  const isAllAnswered = enrolledHomeworkDetails.every(
    (enrolledHomeworkDetail) => enrolledHomeworkDetail.submitted_answer !== ""
  );
  const isNotFileChange = enrolledHomeworkDetails.every((detail, index) => {
    return detail.file_name === enrolledsHomeworkDetails[index]?.file_name;
  });

  //s3에 이미 올라간 경우에 다시 제출하면 파일 정보가 없어 열수없는 파일이 올라가므로 이걸 방지하기 위한 조건을 추가
  const isAllUploaded = enrolledsHomeworkDetails.every(
    (enrolledsHomeworkDetail) =>
      enrolledsHomeworkDetail?.submitted_answer?.includes("media")
  );

  const { isPosted, handleUpload, checkFileBeforeUpload } = useFileUpload({
    type,
  });
  const postMyHomeworks = usePostMyHomeworks(
    enrolled_homework_id,
    enrolledId,
    homeworkId
  );
  const canGoNextLecture = isFeedbackRequired
    ? isAllAnswered && canGoNextLectureStatusValues.includes(status)
    : isAllAnswered;
  const canUpdateHomework =
    isAllAnswered &&
    (!isFeedbackRequired || [null, "REJECTED", "SUBMITTED"].includes(status));

  const handleSubmitHomework = async () => {
    logHomeworkCompleted();
    if (isFeedbackRequired) {
      if (
        fileInfo.type === "" &&
        status === StatusEnum.SUBMITTED &&
        type.includes("file")
      ) {
        alert(NEED_SCREENING_ALERT);
        window.scrollTo(0, 0);
        return;
      }
      if (canGoNextLecture) {
        await navigateToNext();
        return;
      }
      if (type.includes("file")) {
        checkFileBeforeUpload().then((isLoaded) => {
          if (isLoaded) {
            console.log("파일이 성공적으로 로드되었습니다.");
            handleUpload().then(async () => {
              alert(UPDATE_SUCCESS_ALERT);
              window.scrollTo(0, 0);
            });
          }
        });
      } else if (!type.includes("file")) {
        await postMyHomeworks.mutateAsync(enrolledHomeworkDetails);
        alert(UPDATE_SUCCESS_ALERT);
        window.scrollTo(0, 0);
      }
    } else {
      if (type.includes("file")) {
        if (isAllUploaded && isNotFileChange) {
          //파일이 변경된게 없고 모두 s3에 업로드 돼있으면 그냥 넘기기
          await navigateToNext();
          return;
        }
        handleUpload().then(async () => {
          alert("숙제를 제출했습니다.");
          await navigateToNext();
        });
      } else if (!type.includes("file")) {
        await postMyHomeworks.mutateAsync(enrolledHomeworkDetails);
        await navigateToNext();
      }
    }
  };

  const [throttledSubmitHomework] = useThrottle(handleSubmitHomework, 3000);

  if (isMobile) {
    return (
      <HomeworkGnbMobile
        isInit={isInit}
        qnaLink={qnaLink}
        isFeedbackRequired={isFeedbackRequired}
        courseTitle={courseTitle}
        syllabusUnit={syllabusUnit}
        enrolled_homework_id={enrolled_homework_id}
        canGoNextLecture={canGoNextLecture}
        canUpdateHomework={canUpdateHomework}
        handleSubmitHomework={throttledSubmitHomework}
      />
    );
  } else {
    return (
      <HomeworkGnbDesktop
        week={week}
        isInit={isInit}
        isPosted={isPosted}
        isOpenList={isOpenList}
        courseTitle={courseTitle}
        syllabusUnit={syllabusUnit}
        canGoNextLecture={canGoNextLecture}
        canUpdateHomework={canUpdateHomework}
        isFeedbackRequired={isFeedbackRequired}
        handleSubmitHomework={throttledSubmitHomework}
      />
    );
  }
};
