import * as S from "./HomeworkGnbDesktop.style";
import { DesktopNextBtn } from "../../../../../pages/LecturePageV3/LecNextBtnV3/DesktopNextBtn/DesktopNextBtn";
import { BackIconSvg } from "../../../../../pages/LecturePageV3/Icons";
import { useNavigateToRoadmap } from "../../../../../hooks/useNavigateToRoadmap";
import { useNextContentCtaText } from "../../../../../businesslogics/OrderedEnrollmentContents/usecase/OECHandling";
import { useEffect, useState } from "react";

export const HomeworkGnbDesktop = ({
  week,
  isInit,
  isPosted,
  isOpenList,
  courseTitle,
  syllabusUnit,
  canGoNextLecture,
  canUpdateHomework,
  isFeedbackRequired,
  handleSubmitHomework,
}) => {
  const [isClickable, setIsClickable] = useState(
    canGoNextLecture ? true : canUpdateHomework
  );
  const navigateToRoadmap = useNavigateToRoadmap();
  const ctaText = useNextContentCtaText(
    isFeedbackRequired,
    canGoNextLecture,
    isFeedbackRequired
      ? canGoNextLecture
        ? "다음 강의"
        : "숙제 제출하기"
      : "다음 강의"
  );

  const handleClick = () => {
    setIsClickable(false);
    handleSubmitHomework();
  };

  useEffect(() => {
    if (isPosted) {
      setIsClickable(canGoNextLecture ? true : canUpdateHomework);
    }
  }, [isPosted]);

  return (
    <S.HomeworkGnbWrapper isOpenList={isOpenList}>
      <S.HomeworkGnbLeftSide>
        <S.GoPrevPageBtn
          onClick={() => {
            navigateToRoadmap();
          }}
        >
          <BackIconSvg />
        </S.GoPrevPageBtn>
        <S.LectureTitleAndWeekWrapper>
          <S.LectureTitle>{courseTitle}</S.LectureTitle>
          <S.LectureTitleAndWeekDivider />
          <S.LectureWeek>
            {week}
            {syllabusUnit}
          </S.LectureWeek>
        </S.LectureTitleAndWeekWrapper>
      </S.HomeworkGnbLeftSide>
      <S.HomeworkGnbRightSide>
        <DesktopNextBtn
          text={ctaText}
          onClick={handleClick}
          isActive={isInit}
          isClickable={isClickable}
        />
      </S.HomeworkGnbRightSide>
    </S.HomeworkGnbWrapper>
  );
};
